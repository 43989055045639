export const environment = {
  production: true,
  apiUrl: 'https://api-uat.munily.xyz/api',
  newApiUrl: 'https://api-uat.munily.xyz',
  baseUrl: 'https://dashboard-uat.munily.xyz',
  socketUrl: 'https://socket-uat.munily.xyz',
  firebase: {
    apiKey: 'AIzaSyDvbymJjE3jW7HC4ZhAbJFiDlwRgTGCH7U',
    authDomain: 'munily-test.firebaseapp.com',
    databaseURL: 'https://munily-test.firebaseio.com',
    projectId: 'munily-test',
    storageBucket: 'munily-test.appspot.com',
    messagingSenderId: '728423469602',
    appId: '1:728423469602:android:ea2f0839eb20e7e4',
  },
  geoapifyUrl: 'https://api.geoapify.com/v1/geocode/search?text',
  geoapifyApiKey: 'db85710bfb124a93a7d465a5b9355bb8',
  amplitudeApiKey: '46c912ce0505f555a0cfc2682c2a2641',
  amplitudeEnabled: true,
  envDeployed: 'uat'
};
